<template>
  <section
    ref="inicio"
    class="inicio full-width flex items-center x-center parallax"
    :style="{ backgroundImage: 'url(' + dbStore.homeIntro.imgURL + ')' }"
  >
    <article class="presentacion">
      <div class="titlebox">
        <h1 class="title fade1">{{ dbStore.homeIntro.title }}</h1>
      </div>
      <div class="cardscontainer fade2">
        <div class="blackcard contact-intro call-wsp">
          <h2 class="cardtext1">Llámanos por Whatsapp</h2>
          <WspCall />
          <p class="cardtext cardtext_sm">Realiza tu consulta</p>
        </div>

        <div class="bluecard bg-blue">
          <p class="cardtext">{{ dbStore.homeIntro.description }}</p>
          <!--  dividirlo en 3 en firebase en vez de un parrafo -->
        </div>
      </div>
    </article>
  </section>

  <div class="whitespace"></div>

  <section ref="servicios" 
  class="inicioservicios" >
    <article class="bigcard">
      <div class="bigcardwrap">
        <h2 class="cardtexttitle">{{ dbStore.homeServices.title }}</h2>
        <p class="cardtext paragraph weight-400">{{ dbStore.homeServices.description }}</p>
        <!--  En este parrafo hay unos punto y coma mal espaciados que vinieron asi   -->
        <router-link class="iniciobtn" to="/servicios">
          <span>{{
            dbStore.homeServices.textLink
          }}</span>
        </router-link>
      </div>
    </article>
    <div class="prov">
      <img
        :src="dbStore.homeServices.imgURL" class="full-width full-height"
      />
    </div>
  </section>

  <div class="whitespace"></div>

  <section ref="disenio" class="iniciodisenio" >
   
    <article class="iniciodiseniowrap full-width">
      <div class="relative-left full-width bg-black">
        <img :src="dbStore.homeServices2.imgURL" class="full-width" />
        <div class="disenioimagen absolute top-0 left-0 full-height" >
          <h2 class="blacktxtbg">{{ dbStore.homeServices2.title }}</h2>
        </div>
      </div>

      <div class="diseniocardswrap">
        <div class="diseniocard relative full-height">
          <div>
            <span class="material-icons-sharp">grid_view</span>
            <h3>
              <span>
                {{ dbStore.homeServices2.box1 }}
              </span>
            </h3>
          </div>
        </div>
        <div class="diseniocard">
          <div>
            <span class="material-icons-sharp">grid_view</span>
            <h3>
              <span>
                {{ dbStore.homeServices2.box2 }}
              </span>
            </h3>
          </div>
        </div>
        <div class="diseniocard">
            <div>
              <span class="material-icons-sharp">grid_view</span>
              <h3>
                <span>
                  {{ dbStore.homeServices2.box3 }}
                </span>
              </h3>
          </div>       
        </div>
      </div>
    </article>
  </section>
  <section class="quote"> 
      <article class="container container_md full-height flex row wrap items-center x-center bg-white txt-center">
        <h2>
          Solicita tu presupuesto
          <span class="block margin-top-5 txt-center call-wsp">
             <WspCall />
          </span>
        </h2>
      </article>
  </section>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import { useDbStore } from "@/stores/dbStore";
import WspCall from '@/components/share-tools/WspCall'
export default {
  name: "Inicio",
  setup() {
    const inicio = ref(null);
    const servicios = ref(null);
    const disenio = ref(null);
    const dbStore = useDbStore();
    const dataProducts = reactive({});

    return {
      ...toRefs(dataProducts),
      dbStore,
      inicio,
      servicios,
      disenio,
    };
  },
  components:{
    WspCall
  }
};
</script>
